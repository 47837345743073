import React from "react";
import { validateForNumber } from "lib/utils/helper.js";

export const NewTextField = ({
  input,
  label,
  meta,
  type = "text",
  disabled = false,
  helperText = "",
}) => {
  const hasError = meta && meta.touched && meta.error;
  const name = input.name;
  return (
    <div>
      <label
        className="text-gray-1050 font-normal mb-8px text-body block"
        htmlFor={`field-${name}`}
      >
        {label}
      </label>
      <input
        id={`field-${name}`}
        className={`p-16px border-1.5px border-gray-1010 focus:outline-none focus:border-primary rounded-sm w-full text-base text-gray-700 leading-tight ${
          hasError ? "border-red-600" : "border-gray-300"
        }
          ${meta.active ? "" : "hover:bg-gray-1200"} ${
          disabled && "cursor-not-allowed"
        }`}
        type={type}
        {...input}
        disabled={disabled}
        onKeyDown={(event) => {
          if (event.target.type === "number" && validateForNumber(event.key)) {
            event.preventDefault();
          }
        }}
        onWheel={(event) => {
          event.preventDefault();
          event.target.blur();
        }}
        onBlur={(e) => {
          input.onChange(e.target.value?.trim());
          input.onBlur(e);
        }}
      />
      <p className="text-red-600 font-normal text-badge">
        {hasError && !meta.active && meta.error}
        {!helperText && " "}
      </p>
      {meta?.active && helperText && (
        <p className="text-xs text-gray-200 mt-1">{helperText}</p>
      )}
      <style jsx>{`
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      `}</style>
    </div>
  );
};
