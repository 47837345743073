import {
  DisbursementActivityState,
  DisbursementBlockingReason,
} from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/constants";
import { getNextThreeYears } from "lib/utils/dateUtils";

export const defaultOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const availabilityStatusOptions = [
  {
    label: "Online",
    value: true,
  },
  { label: "Offline", value: false },
];

export const genderOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export const maritalStatusOptions = [
  { label: "SINGLE", value: "SINGLE" },
  { label: "MARRIED", value: "MARRIED" },
  { label: "DIVORCED", value: "DIVORCED" },
];

export const fathersTitleOptions = [
  { label: "Mr", value: "MR" },
  { label: "Late", value: "LATE" },
  { label: "Divorced", value: "DIVORCED" },
];

export const mothersTitleOptions = [
  { label: "Mrs", value: "MRS" },
  { label: "Ms", value: "DIVORCED" },
  { label: "Late", value: "LATE" },
];

export const residenceTypeOptions = [
  { label: "Rented", value: "RENTED" },
  { label: "Owned", value: "OWNED" },
  { label: "Govt Quarter", value: "GOVT_QUARTER" },
  { label: "Company Quarter", value: "COMPANY_QUARTER" },
  { label: "Leased", value: "LEASED" },
];

export const yesOrNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const highestEducationOptions = [
  {
    label: "Undergraduate (Bachelors) degree",
    value: "UG",
  },
  {
    label: "Postgraduate (Masters) degree",
    value: "PG",
  },
];

export const gpaBaseOptions = [
  { label: "4", value: 4 },
  { label: "7", value: 7 },
  { label: "10", value: 10 },
  { label: "100", value: 100 },
];

export const pdStatusOptions = [
  { label: "PENDING", value: "PENDING" },
  { label: "CALL_NOT_CONNECTED", value: "CALL_NOT_CONNECTED" },
  { label: "DONE", value: "DONE" },
];

export const ofacStatusOptions = [
  { label: "DETAILS_MATCH", value: "DETAILS_MATCH" },
  { label: "NO_DETAILS_MATCH", value: "NO_DETAILS_MATCH" },
];

export const relationshipOptions = [
  { label: "Father", value: "FATHER" },
  { label: "Mother", value: "MOTHER" },
  {
    label: "Grandfather",
    value: "GRANDFATHER",
  },
  {
    label: "Grandmother",
    value: "GRANDMOTHER",
  },
  { label: "Brother", value: "BROTHER" },
  { label: "Sister", value: "SISTER" },
  { label: "Uncle", value: "UNCLE" },
  { label: "Aunt", value: "AUNT" },
  { label: "Cousin", value: "COUSIN" },
  { label: "Other", value: "OTHER" },
];

export const admitReceivedOptions = [
  {
    label: "Waiting for admit",
    value: "WAITING_FOR_ADMIT",
  },
  {
    label: "Received initial admit",
    value: "RECEIVED_INITIAL_ADMIT",
  },
  {
    label: "Received final admit",
    value: "RECEIVED_FINAL_ADMIT",
  },
];

export const mmpOptions = [
  { value: 5, label: "$50" },
  { value: 6, label: "$60" },
  { value: 7, label: "$70" },
  { value: 8, label: "$80" },
  { value: 9, label: "$90" },
  { value: 10, label: "$100" },
];

export const activeCosignerEmploymentTypesOptions = [
  { label: "Salaried", value: "SALARIED" },
  {
    label: "Self-Employed",
    value: "SELFEMPLOYED",
  },
  { label: "Pensioner", value: "PENSIONER" },
];

export const extraCosignerEmploymentTypesOptions = [
  { label: "Salaried", value: "SALARIED" },
  {
    label: "Self-Employed",
    value: "SELFEMPLOYED",
  },
  { label: "Pensioner", value: "PENSIONER" },
  { label: "Unemployed", value: "UNEMPLOYED" },
];

export const totalExperienceOptions = [
  {
    label: "Less than 5 years",
    value: 30,
  },
  { label: "5 - 10 years", value: 90 },
  {
    label: "10 - 20 years",
    value: 180,
  },
  {
    label: "More than 20 years",
    value: 246,
  },
];

export const companyVintageOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "More than 10", value: 11 },
];

export const patOptions = [
  {
    label: "1 - 10 lakhs",
    value: "1 - 10,00000",
  },
  {
    label: "10 - 25 lakhs",
    value: "10,00000 - 25,00000",
  },
  {
    label: "25 - 50 lakhs",
    value: "25,00000 - 50,00000",
  },
  {
    label: "More than 50 lakhs",
    value: "more than 50,00000",
  },
];

export const documentReuploadOptions = [
  { label: "Add-on Document", value: "ADDON" },
];

export const documentInitialUploadOptions = [
  {
    label: "Replacement Document",
    value: "REPLACEMENT",
  },
  { label: "Add-on Document", value: "ADDON" },
];

export const courseTenureOptions = () => {
  const options = [];
  for (let courseMonth = 6; courseMonth <= 30; courseMonth++) {
    options.push({
      label: `${courseMonth} months`,
      value: courseMonth,
    });
  }
  return options;
};

export const getLastTwelveMonths = () => {
  const monthName = new Array(
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER"
  );
  const currentDate = new Date();
  currentDate.setDate(1);
  const dateOptions = [{ label: "Select", value: null }];
  for (let month = 0; month <= 11; month++) {
    dateOptions.push({
      label:
        monthName[currentDate.getMonth()] + " " + currentDate.getFullYear(),
      value:
        monthName[currentDate.getMonth()] + " " + currentDate.getFullYear(),
    });
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  return dateOptions;
};

export const salariedSubTypeOptions = [
  { label: "Goverment", value: "GOVERNMENT" },
  { label: "Private", value: "PRIVATE" },
];

export const selfEmployedSubTypeOptions = [
  { label: "Professional", value: "PROFESSIONAL" },
  { label: "Non Professional", value: "NON_PROFESSIONAL" },
];

export const portalPDStatusOptions = [
  { label: "PENDING", value: false },
  { label: "DONE", value: true },
];
export const processingFeeCollectionTypeOptions = [
  {
    label: "Upfront PF to be collected",
    value: "COLLECT_PF_UPFRONT",
  },
  {
    label: "To be added in the loan fee",
    value: "COLLECT_PF_LOAN_FEE",
  },
];

export const topUpReason = [
  {
    label: "Increase in tuition fees",
    value: "tuitionFees",
  },
  {
    label: "Increase in living expenses",
    value: "livingExpenses",
  },
  {
    label: "Lower amount sanctioned originally",
    value: "lowerAmountSanctioned",
  },
  {
    label: "Student opted for additional credits",
    value: "additionalCredits",
  },
  {
    label: "Other Reason",
    value: "otherReason",
  },
];

export const disbursementStatusOptions = [
  {
    label: "Active",
    value: DisbursementActivityState.ACTIVATE,
  },
  { label: "Disabled", value: DisbursementActivityState.DEACTIVATE },
];

export const disbursementBlockingReasonOptions = [
  {
    label: "Non Funded University",
    value: DisbursementBlockingReason.NON_FUNDED_UNIVERSITY,
  },
  {
    label: "Poor Academics",
    value: DisbursementBlockingReason.POOR_ACADEMICS,
  },
  {
    label: "Loan Closed",
    value: DisbursementBlockingReason.LOAN_CLOSED,
  },
  {
    label: "Discontinued Masters",
    value: DisbursementBlockingReason.DISCONTINUED_MASTERS,
  },
  {
    label: "Fraudulent Documents Submitted",
    value: DisbursementBlockingReason.FRAUDULENT_DOCUMENTS_SUBMITTED,
  },
];

export const discontinuedMastersOptions = [
  {
    label: "CAME BACK TO INDIA",
    value: "CAME BACK TO INDIA",
  },
  {
    label: "IN US",
    value: "IN US",
  },
];

export const sofrMonthsOptions = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "October",
    value: 10,
  },
];

export const sofrYearOptions = getNextThreeYears().map((year) => ({
  label: year,
  value: year,
}));

export const loanTenureOptions = [
  {
    label: "36 months",
    value: 36,
  },
  {
    label: "60 months",
    value: 60,
  },
  {
    label: "84 months",
    value: 84,
  },
  {
    label: "96 months",
    value: 96,
  },
  {
    label: "108 months",
    value: 108,
  },
  {
    label: "120 months",
    value: 120,
  },
];
