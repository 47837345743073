import React, { useState } from "react";
import Select from "react-select";

export const NewSelect = ({
  input,
  meta,
  label,
  options,
  onChange,
  isRequired = false,
  disabled = false,
}) => {
  const hasError = meta && meta.touched && meta.error;
  const [value, setValue] = useState("");
  return (
    <div className="relative">
      <div>
        <label
          className="text-gray-1050 font-normal mb-8px block text-body"
          htmlFor={`field-${input.name}`}
        >
          {label}
        </label>
        <Select
          instanceId={`field-${input.name}`}
          className={`border-1.5px border-gray-1010 relative focus:outline-none focus:border-primary rounded-sm w-full text-base text-gray-700 leading-tight ${
            hasError ? "border-red-600" : ""
          } ${disabled && "cursor-not-allowed"}`}
          styles={{
            control: (styles, state) => {
              return {
                ...styles,
                borderWidth: 0,
                background: disabled ? "#EFEFEF4D" : "white",
                borderRadius: "4px",
                paddingLeft: "10px",
                paddingRight: "10px",
                cursor: "pointer",
                borderColor: state.isFocused ? "#443eff" : "gray",
                boxShadow: state.isFocused
                  ? "0 0 0 1px #443eff"
                  : styles.boxShadow,
              };
            },
            menu: (styles) => {
              return {
                ...styles,
                zIndex: 11,
              };
            },
            input: (styles) => {
              return {
                ...styles,
                paddingTop: "12px",
                paddingBottom: "12px",
                width: "100%",
              };
            },
            singleValue: (styles) => {
              return {
                ...styles,
              };
            },
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? "rgb(66, 82, 110)" : "white",
              color: state.isFocused ? "white" : "black",
            }),
          }}
          options={options}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={(v) => {
            input.onChange(v.value);
            if (onChange) onChange(v.value);
            setValue(v.value);
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          required={isRequired}
          isDisabled={disabled}
          value={options?.find((a) => a.value === input.value) || value}
        />
      </div>
      <p className="text-red-600 font-normal text-badge">
        {hasError && !meta.active && meta.error}&nbsp;
      </p>
    </div>
  );
};
